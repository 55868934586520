import React, {useEffect} from 'react';
import {HashRouter, Route, Routes} from "react-router-dom";
import Expiredlink from "./pages/error/page";
import PaymentLink from "./pages/links/page";
import './globals.css';
import {OIDCWrapper} from "./pages/analysis/page";
import ReproveOwner from "./pages/error/reprove_owner";
import MaintenancePage from "./pages/maintenance/page";


function App() {
    useEffect(() => {
        if (window.location.hash === "") {
            window.location.href = "#";
        }
    });

    return (
        <HashRouter>
            <Routes>
                <Route path={"analysis/:linkID"} element={<>
                    <OIDCWrapper/>
                </>
                }>
                </Route>
                <Route path={"error"} element={
                    <Expiredlink/>
                }>
                </Route>
                <Route path={"maintenance"} element={
                    <MaintenancePage/>
                }>
                </Route>
                <Route path={"links/:linkID"} element={
                    <PaymentLink/>
                }>
                </Route>
                <Route path={"error/:linkID/owner"} element={
                    <ReproveOwner/>
                }>
                </Route>
                <Route path={"*"} element={
                    <Expiredlink/>
                }/>
            </Routes>
        </HashRouter>
    );
}

export default App;
