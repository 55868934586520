import React, {useEffect, useState} from 'react';

import Header from '../../components/Header';
import LoadingModal from '../../components/Loading/loading';
import {useParams} from "react-router-dom";
import {fetchLink} from "../../api/api";
import Button from '../../components/Button/button';
import {allowedIDs, isInMaintenance} from "../maintenance/page";

export default function PaymentLink() {
    let {linkID} = useParams();

    const [link, setLink] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (isInMaintenance && (!linkID || !allowedIDs.includes(linkID))) {
            window.location.href = "/#/maintenance";
        }

        fetchLink(linkID!).then(
            (response) => {
                setLink(response.data.link);
                if (
                    response.data['link'] !== undefined
                ) {
                    setLoading(false);
                } else {
                    document.location.href = '/error';
                }
            }
        ).catch(() => {
            document.location.href = '/error';
        })
    }, [linkID]);

    return (
        <>
            {loading ? (
                <LoadingModal/>
            ) : (
                <>
                    {link !== undefined ? (
                        <Header
                            purchaseDescription={{
                                value: link['amount'],
                                storeName: link['merchant_name'],
                            }}
                        />
                    ) : null}
                    <main className='flex flex-col items-center justify-between lg:bg-blue-30'>
                        <section
                            id='content'
                            className='flex flex-col justify-between gap-8 py-6 px-4 lg:p-10 bg-white rounded-lg w-full max-w-[564px]'
                        >
                            <h3 className='text-center text-2xl leading-7 font-bold text-blue-30'>
                                Pague com o Pix Parcelado do <br/> DM Pag na{' '}
                                {link !== undefined && link['merchant_name']}!
                            </h3>
                            <div className='w-full pl-6'>
                                <ul className='space-y-6'>
                                    <li className='flex items-center gap-4'>
                                        <div
                                            className='flex h-10 w-10 items-center justify-center rounded-full bg-blue-90 text-2xl leading-7 font-bold text-blue-30'>
                                            <div className='h-10 w-10 relative'>
                                                <p className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>1</p>
                                            </div>
                                        </div>
                                        <p>
                                            Passe pela <strong> análise de crédito em segundos</strong>.
                                        </p>
                                    </li>
                                    <li className='flex items-center gap-4'>
                                        <div
                                            className='flex h-10 w-10 items-center justify-center rounded-full bg-blue-90 text-2xl leading-7 font-bold text-blue-30'>
                                            <div className='h-10 w-10 relative'>
                                                <p className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>2</p>
                                            </div>
                                        </div>
                                        <p>
                                            Escolha o{' '}
                                            <strong>
                                                melhor plano de parcelamento
                                            </strong>
                                            .
                                        </p>
                                    </li>
                                    <li className='flex items-center gap-4'>
                                        <div
                                            className='flex h-10 w-10 items-center justify-center rounded-full bg-blue-90 text-2xl leading-7 font-bold text-blue-30'>
                                            <div className='h-10 w-10 relative'>
                                                <p className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>3</p>
                                            </div>
                                        </div>
                                        <p>
                                            Libere sua compra com um <strong>Pix de entrada.</strong>
                                        </p>
                                    </li>
                                    <li className='flex items-center gap-4'>
                                        <div
                                            className='flex h-10 w-10 items-center justify-center rounded-full bg-blue-90 text-2xl leading-7 font-bold text-blue-30'>
                                            <div className='h-10 w-10 relative'>
                                                <p className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>4</p>
                                            </div>
                                        </div>
                                        <p>
                                            Pague a próxima parcela no Pix <strong>só daqui um mês</strong>.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <Button
                                variant='solid'
                                type='navigation'
                                to={`/analysis/${linkID}/`}
                            >
                                Criar ou acessar minha conta DM Pag
                            </Button>
                        </section>
                    </main>
                </>
            )}
        </>
    );
}
