import React, {useEffect, useMemo, useState} from 'react';

import Header from '../../components/Header';

import {AuthProvider, AuthProviderProps} from "react-oidc-context";
import animationData from '../../assets/animationAnalisys.json';
import Lottie from "react-lottie";
import {fetchLink, fetchPay, PayRequest} from "../../api/api";
import {useParams} from "react-router-dom";
import {hasAuthParams, useAuth,} from "react-oidc-context";
import {jwtDecode} from "jwt-decode";
import {allowedIDs, isInMaintenance} from "../maintenance/page";

export default function DataAnalysis() {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);
    let {linkID} = useParams();

    useEffect(() => {
        const createPaymentIntent = async () => {
            try {
                const linkResponse = await fetchLink(linkID!);

                let userToken = '';
                if (auth.isAuthenticated) {
                    userToken = auth.user?.access_token || '';

                    const tokenParsed: Record<string, any> = jwtDecode(userToken)
                    if (!tokenParsed) {
                        auth.signinRedirect();
                        return;
                    }
                    const linkData: PayRequest = {
                        user: {
                            name: tokenParsed.fullName,
                            document: {
                                number: tokenParsed.cpf,
                                type: 'CPF',
                            },
                            birth_date: tokenParsed.birthDate,
                            email: tokenParsed.email,
                            phone_number: tokenParsed.phoneNumber,
                            address: {},
                        },
                        link: linkResponse.data.link,
                        account_id: linkResponse.data.link.account_id,
                        organization_id: linkResponse.data.link.organization_id
                    }

                    const data = await fetchPay(linkData, userToken);

                    if (data.error) {

                        if (data.status === 403) {
                            window.location.href = "/#/error/" + linkID + "/owner";
                            return;
                        }

                        alert("Erro ao processar solicitação! " + data.reason + ".\n\nTente novamente ou entre em contato pelo chat de atendimento.");
                        setTimeout(() => {
                            window.location.href = "/#/links/" + linkID;
                        }, 2000)
                    } else {
                        window.location.href = data.checkout_url
                    }
                } else {
                    return;
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        createPaymentIntent();
    }, [auth.isAuthenticated]);

    // automatically sign-in
    useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    useEffect(() => {
        if (isInMaintenance && (!linkID || !allowedIDs.includes(linkID))) {
            window.location.href = "/#/maintenance";
        }
    }, [linkID]);

    if (!auth) {
        return null;
    }

    return (
        <div className='pb-30 h-screen bg-blue-30'>
            <Header/>
            <div
                className='mt-12 flex flex-col items-center justify-center text-center text-white w-[clamp(240px,90%,564px)] mx-auto'>
                <p className='mb-[14px] text-center text-[28px] font-bold leading-[33.6px] text-white'>
                    Carregando...
                </p>
                <div className='w-350 h-350 relative'>
                    <div
                        className='w-350 h-350 absolute left-1/2 top-1/2 -translate-x-[50%] -translate-y-[50%] transform'>
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: animationData,
                            }}
                            height={350}
                            width={350}
                        />
                    </div>
                    <div className='w-350 relative z-10 mx-auto -left-2 my-10'>
                        <img
                            src={require('../../assets/analysis-img.png')}
                            alt='imagem de mão esperando análise'
                            width={220}
                            height={220}
                            className='ml-4 mt-8'
                        />
                    </div>
                </div>
                <p className='w-[260px] text-center leading-6 text-white'>
                    Aguarde um pouquinho, estamos preparando tudo pra você.
                </p>
            </div>
        </div>
    );
}

export const OIDCWrapper = () => {
    console.log("KEYCLOAK URL", process.env.REACT_APP_KEYCLOAK_URL)
    const oidcConfig: AuthProviderProps = useMemo(() => {
        return {
            client_id: "dmpag-app",
            authority: process.env.REACT_APP_KEYCLOAK_URL,
            redirect_uri: window.location.href,
            onSigninCallback: (): void => {
                window.history.replaceState({}, document.title, window.location.pathname);
            }
        };
    }, [])

    return <AuthProvider
        {...oidcConfig}
    >
        <DataAnalysis/>
    </AuthProvider>;
}
