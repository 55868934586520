import React from "react";
import Header from "../../components/Header";

export const isInMaintenance = false;

export var allowedIDs = [
    '2ssKw3UGhnJgJikfuVcFwOcESwA',
];

function MaintenancePage() {
    return (
        <>
            <Header/>
            <div className="flex items-center justify-center min-h-screen bg-gray-100 p-5 text-center">
                <div className="bg-white p-10 rounded-xl shadow-md max-w-md">
                    <div className="text-6xl text-blue-600 mb-4">&#9888;</div>
                    <h1 className="text-2xl font-bold text-blue-600 mb-4">Estamos em Manutenção</h1>
                    <p className="text-lg mb-2">Desculpe pelo incômodo. Estamos trabalhando para melhorar seu
                        atendimento.
                        Voltaremos em breve.</p>
                    <p className="text-lg">Agradecemos sua compreensão.</p>
                </div>
            </div>
        </>
    );
}

export default MaintenancePage;
